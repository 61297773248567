<template>
    <div>
        <span v-if="row.paymentProcessStatus.toLowerCase()=='draft'" class="btn btn-sm btn-light"  v-b-tooltip.hover  :title="`Masih draft | ${row.fakturProsesNote}`"> <i class="fe fe-alert-circle"/></span>
        <span class="btn btn-sm btn-light"  v-b-tooltip.hover  title='Process scan faktur sedang berlangsung' v-if="row.fakturProsesStatus==='processing'">
            <i class="fa-solid fa-spin fa-spinner"></i></span>

        
        <span v-b-tooltip.hover  :title=row.fakturProsesNote>        
            <span v-if="row.tanpaFaktur">
                <span class="btn btn-sm btn-info"> <i class="fe fe-check"/></span> Tanpa Faktur
            </span> 
            <span v-else>
                <span class="btn btn-sm btn-success" v-if="row.fakturProsesStatus==='done' && !row.tanpaFaktur"> <i class="fe fe-check-circle"/></span>
                <span class="btn btn-sm btn-warning" v-if="row.fakturProsesStatus==='problematic'"> <i class="fe fe-alert-triangle"/></span>
                <span v-if="row.fakturProsesStatus==='failed'"><span class="btn btn-sm btn-danger"> <i class="fe fe-x-circle"/></span> Gagal scan faktur</span>
                <span v-if="row.fakturProsesStatus==='empty'"><span class="btn btn-sm btn-danger"> <i class="fe fe-x-circle"/></span> Gagal scan faktur</span>
                <span v-if="(row.fakturProsesStatus=='done'||row.fakturProsesStatus=='problematic')  ">
                    <ol v-if="isArray(fakturValues)" >
                        <li v-for="(item, idx) in toList(fakturValues)">{{ item }} </li>
                    </ol>
                    <span v-else> {{ fakturValues }}</span>
                </span>
            </span>           
        </span>
    </div>
</template>
<script setup>
    defineProps(["row","fakturValues"])    
</script>

<script>

    function isArray(fakturValues)
    {
        if(fakturValues===null || fakturValues===undefined) return false;
        return fakturValues.includes(',');
    }

    function toList(fakturValues){
        const ary=fakturValues.split(',');
        return ary;
    }
</script>